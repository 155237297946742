import React from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {formatPrice} from '../../utils/currency-format'

const CENTER_PLANS = [
  'command center',
  'business center',
  'marketing center',
  'reporting center',
]

const CartItem = ({item, isToday = false, paymentPreview}) => {
  const nameLower = item.name.toLowerCase()
  const isSEOOrTL =
    (nameLower.includes('seo') &&
      item.name !== 'SEO Keywords' &&
      item.name !== 'SEO PowerBoost') ||
    nameLower.includes('thryv leads')

  // const isAlphaSEO = (item.name === 'SEO' ||
  // item.name === 'SEO Keyword' ||
  // item.name === 'SEO PowerBoost')
  const itemQuantity =
    isToday && paymentPreview && isSEOOrTL ? 1 : item.quantity || 1

  const isAlphaSeoUpgrade =
    isToday &&
    paymentPreview &&
    (item.name === 'SEO' ||
      item.name === 'SEO Keywords' ||
      item.name === 'SEO PowerBoost')
      ? true
      : false

  let planPrice = isAlphaSeoUpgrade
    ? item.currency?.unitAmount
    : item.currency?.unitAmount * itemQuantity
  const isCenter = CENTER_PLANS.some(plan =>
    item.name.toLowerCase().includes(plan),
  )

  if (
    item.currency.currency === 'AUD' &&
    (item.code === 'seo-5-thryv-m2m' ||
      item.code === 'seombg-5-thryv-m2m' ||
      item.code === 'addon-4-seombg-m2m' ||
      item.code === 'addon-4-seo-m2m')
  ) {
    planPrice = planPrice + planPrice * 0.1
  }
  if (
    item.currency.currency === 'AUD' &&
    (item.code === 'addon-5-seokey-m2m' ||
      item.code === 'addon-5-seopb-m2m' ||
      item.code === 'seobase-5-thryv-m2m') &&
    isToday &&
    isAlphaSeoUpgrade
  ) {
    planPrice = planPrice + planPrice * 0.1
  }
  return (
    <div className="monthly_container">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex" style={{columnGap: '8px'}}>
          {isCenter && !item.isCoupon && (
            <img
              style={{borderRadius: '5px'}}
              src={item.img}
              alt={item.plan}
              height="24px"
              width="24px"
            />
          )}
          <ParagraphText
            variant="reg"
            className="m-0"
            style={{color: '#4D4D4D'}}
          >
            {item.name}
            {item.quantity && item.quantity > 1 && !isSEOOrTL
              ? ` (${item.quantity}x)`
              : ''}
          </ParagraphText>
        </div>
        <ParagraphText variant="reg" className="m-0" style={{color: '#4D4D4D'}}>
          {item.isIncentive && '-'}
          {item.isCoupon ? '' : formatPrice(planPrice)}
        </ParagraphText>
      </div>
    </div>
  )
}

export {CartItem}
