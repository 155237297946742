/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useAuth0} from '@auth0/auth0-react'
import React, {useState} from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import {countryIsoCodeAlpha3} from '../utils/country-codes'
import {countryIsoCodeAlpha2} from '../utils/country-codes'
import {iso3toiso2} from '../utils/iso3-to-iso2'
import {dateTimeConverter} from '../utils/timezones'
import {useNavigate} from 'react-router-dom'
import {Select, Avatar, Button, Modal, ModalTitle} from '@thryvlabs/maverick'
import {
  LeftAlignedDropdowns,
  ResultsLayoutContainer,
} from '../components/ui/results-layout'
import {ResultsTable} from '../components/results-table'
import {DetailsModal} from '../components/details-modal'
import {DateTimeModal} from '../components/date-time-modal.js'
import {toast} from 'react-toastify'
import {SearchButton} from './search-button'
import Pagination from './pagination'
import {useFlags} from 'launchdarkly-react-client-sdk'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import {TabOptions} from './tab-options'

let ENDPOINT
const timeZone = [
  {name: 'Today', value: 'Today'},
  {name: 'Past', value: 'Past'},
  {name: 'Tomorrow', value: 'Tomorrow'},
  {name: 'Upcoming', value: 'Upcoming'},
]

const timeZones = [
  {name: `Customer's Time Zone`, value: 'customer'},
  {name: 'Hawaii', value: 'Pacific/Honolulu'},
  {name: 'Alaska', value: 'America/Juneau'},
  {name: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles'},
  {name: 'Arizona Time', value: 'America/Phoenix'},
  {name: 'Mountain Time (US & Canada)', value: 'America/Denver'},
  {name: 'Central Time (US & Canada)', value: 'America/Chicago'},
  {name: 'Eastern Time (US & Canada)', value: 'America/New_York'},
  {name: 'Eastern Time (Cayman)', value: 'America/Cayman'},
  {name: 'Atlantic Time (Canada)', value: 'America/Halifax'},
  {name: 'Atlantic Time (Barbados)', value: 'America/Barbados'},
  {name: 'Perth', value: 'Australia/Perth'},
  {name: 'Adelaide', value: 'Australia/Adelaide'},
  {name: 'Darwin', value: 'Australia/Darwin'},
  {name: 'Brisbane', value: 'Australia/Queensland'},
  {name: 'Sydney', value: 'Australia/Sydney'},
  {name: 'Melbourne', value: 'Australia/Melbourne'},
  {name: 'Hobart', value: 'Australia/Hobart'},
  {name: 'Wellington', value: 'Pacific/Auckland'},
  {name: 'Auckland', value: 'Pacific/Auckland'},
  {name: 'Christchurch', value: 'Pacific/Auckland'},
  {name: 'Dunedin', value: 'Pacific/Auckland'},
  {name: 'Chatham Islands', value: 'Pacific/Chatham'},
]

const DEFAULT_TIMEZONE = {
  name: 'Central Time (US & Canada)',
  value: 'America/Chicago',
}

const modalTitles = {
  demo: 'Thryv Demo Request',
  onboarding: 'Thryv Onboarding Appointment',
  'creative consultation': 'Thryv Creative Consultation Appointment',
  creative: 'Thryv Creative Consultation Appointment',
  alignment: 'Thryv Alignment Call',
}

const demoModalTitles = {
  Thryv: 'Thryv Demo Request',
  'Business Center': 'Business Center Demo Request',
  'Marketing Center': 'Marketing Center Demo Request',
  HIPAA: 'HIPAA Demo Request',
  'Restricted Access': 'Restricted Access Demo Request',
  'Listing Management': 'Listing Management Demo Request - United States',
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return (
      <div>
        <TabOptions screen={'appointments'} />
        {children}
      </div>
    )
  }
  return children
}

function DemoAppointments({
  DemoAppointmentsHeader,
  endpoint,
  showNewDemoButton,
  componentLabel,
  componentLabelPlural,
}) {
  ENDPOINT = endpoint

  /*** HOOKS ***/
  const navigate = useNavigate()
  const client = useAuthClient()
  const {frontendDisplayNewOrder, frontendSideNav} = useFlags()
  const {data, run, isError, error, isLoading} = useAsync({
    status: 'pending',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTime, setSelectedTime] = useState({
    name: 'Today',
    value: 'Today',
  })
  const [selectedTimeZone, setSelectedTimeZone] = useState(DEFAULT_TIMEZONE)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRecords, setCurrentRecords] = useState([])
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [addressNeedsToBeUpdated, setAddressNeedsToBeUpdated] = useState(false)
  const {user} = useAuth0()
  const IS_REP_AUS = user.user_roles.includes('australia')

  /*** FUNCTIONS ***/
  const getTime = () => {
    const today = dayjs(dayjs().toISOString()).unix()
    if (selectedTime.value === 'Today') {
      const start = dayjs(dayjs().startOf('date').toISOString()).unix()
      const end = dayjs(dayjs().endOf('date').toISOString()).unix()
      return `start_date=${start}&end_date=${end}`
    } else if (selectedTime.value === 'Past') {
      return `end_date=${today}`
    } else if (selectedTime.value === 'Tomorrow') {
      const start = dayjs(
        dayjs().add(1, 'day').startOf('date').toISOString(),
      ).unix()
      const end = dayjs(
        dayjs().add(1, 'day').endOf('date').toISOString(),
      ).unix()
      return `start_date=${start}&end_date=${end}`
    } else if (selectedTime.value === 'Upcoming') {
      return `start_date=${today}`
    }
  }

  const setTerm = term => {
    if (!term && !searchTerm) {
      return
    }
    if (term === searchTerm) {
      return `term=${term}`
    }
    setSearchTerm(term)
    if (term) {
      return `term=${term}`
    }
    return
  }

  const getRecords = ({term, time, getAllRecords = false}) => {
    const _time = time || getTime()
    let _term = term || setTerm(searchTerm)
    _term = getAllRecords ? '' : _term
    run(client(`${ENDPOINT}?${_time}${_term ? `&${_term}` : ''}`))
  }

  const handleSearch = value => {
    const query = value.trim()
    const term = setTerm(query)
    if (term) {
      getRecords({term})
    } else {
      getRecords({term, getAllRecords: true})
    }
  }

  const isValidUrl = urlString => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const cancelDemo = async demoId => {
    setLoading(true)
    try {
      await client(`demos/${demoId}`, {method: 'DELETE'})
      toast.success(`${componentLabel} cancelled successfully`)
      const originalIndex = data.findIndex(appt => appt.demo_appt_id === demoId)
      const newData = data.map((value, index) => {
        if (index === originalIndex) {
          return {
            ...value,
            demo_status: 'cancelled',
            alert_type: 'cancelled',
          }
        }
        return {...value}
      })
      await run(new Promise(res => res(newData)))

      const appointmentObj = {
        schedule_status: 'Cancelled',
        appt_id: demoId,
      }
      const result = await client(
        'ordercapture/services/updateScheduledAppointmentStatus',
        {
          data: appointmentObj,
          method: 'PUT',
        },
      )
      if (result.success) {
        console.log('Appointment Cancelled', result.message)
      } else {
        console.log('Error in Appointment cancel', result.message)
      }
    } catch (e) {
      toast.warn('There was a problem with the cancellation')
    }
    setLoading(false)
  }

  const formatZoomUrl = zoomUrl => {
    if (zoomUrl.indexOf('?pwd') === -1 && zoomUrl.indexOf('pwd') !== -1) {
      let pos = zoomUrl.indexOf('pwd')
      return zoomUrl.slice(0, pos) + '?pwd' + zoomUrl.slice(pos + 3)
    } else {
      return zoomUrl
    }
  }

  const addToCalendar = async (dateUTC, appointmentId) => {
    const responseList = await client(`${ENDPOINT}/${appointmentId}`)
    const response =
      (responseList && responseList.length > 0 && responseList[0]) || {}

    const description = `Demo for ${response.cust_first_name} ${
      response.cust_last_name
    } at
    ${response.cust_company}. ${response.cust_first_name} can be contacted at ${
      response.cust_phone
    }
    or ${
      response.cust_email
    }. Please use the following link to attend: ${formatZoomUrl(
      response.demo_url,
    )}`

    const date = dayjs(dateUTC)
    const endDate = date.add(30, 'minute')

    let url = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//hacksw/handcal//NONSGML v1.0//EN',
      'BEGIN:VEVENT',
      'UID:' + date.format('YYYYMMDDTHHmmss'),
      'DTSTAMP:' + dayjs().format('YYYYMMDDTHHmmss'),
      'DTSTART:' + date.format('YYYYMMDDTHHmmss'),
      'DTEND:' + endDate.format('YYYYMMDDTHHmmss'),
      'SUMMARY:Demo',
      'LOCATION:See Invite Details for Zoom Link',
      'DESCRIPTION:' + description,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n')

    let blob = new Blob([url], {type: 'text/calendar;charset=utf-8'})

    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      // Open/Save link in IE and Edge
      window.navigator.msSaveBlob(blob, 'thryv-event.ics')
    } else {
      // Open/Save link in Modern Browsers
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      const url2 = window.URL.createObjectURL(blob)
      a.href = url2
      a.download = 'thryv-event.ics'
      a.click()
      window.URL.revokeObjectURL(url2)
    }
  }

  const setDetailsData = async appointmentId => {
    const responseList = await client(`${ENDPOINT}/${appointmentId}`)
    const response =
      (responseList && responseList.length > 0 && responseList[0]) || {}
    let salesRepName = response.rep_first_name || ''
    salesRepName += response.rep_last_name ? ' ' + response.rep_last_name : ''

    const section1 = {
      sectionType: 'object',
      sectionName: '',
      sectionData: {
        Date: dateTimeConverter(
          response.scheduled_utc,
          response.demo_timezone,
          'date',
          response.demo_datetime,
          selectedTimeZone,
        ),
        Time: dateTimeConverter(
          response.scheduled_utc,
          response.demo_timezone,
          'time',
          response.demo_datetime,
          selectedTimeZone,
        ),
        'Sales Rep Name': salesRepName || '-',
        'Sales Rep Phone': response.rep_phone || '-',
        'Sales Rep Email': response.rep_email || '-',
      },
    }

    let customerName = response.cust_first_name || ''
    customerName += response.cust_last_name ? ' ' + response.cust_last_name : ''

    const city = response.cust_city ? `, ${response.cust_city}` : ''
    const state = response.cust_state ? `, ${response.cust_state}` : ''
    const country = response.cust_country ? `, ${response.cust_country}` : ''
    const fullAddress = `${response.cust_address}${city}${state}${country}`

    const section2 = {
      sectionType: 'object',
      sectionName: 'Customer Information',
      sectionData: {
        Company: response.cust_company || '-',
        Address: fullAddress || '-',
        Name: customerName || '-',
        Phone: response.cust_phone || '-',
        Email: response.cust_email || '-',
        Industry: response.industry_name || '-',
        Website: response.cust_website || '-',
        'Google Map': response.cust_url || '-',
      },
    }

    const section3 = {
      sectionType: 'custom',
      sectionName: 'Notes',
      Component: () => (
        <div className="font-normal text-p-base font-open-sans text-thryv-black-600">
          <p>
            Were you able to complete the &apos;customer journey&apos; with this
            client? <strong>{response.cust_journey}</strong>
          </p>
          <p>
            Are all decision makers going to be present for this meeting?{' '}
            <strong>{response.cust_decision}</strong>
          </p>
        </div>
      ),
    }

    const section4 = {
      sectionType: 'custom',
      sectionName: 'Demo Presenter',
      Component: () => (
        <div className="row font-normal text-p-base font-open-sans text-thryv-black-600">
          <div className="col-5">
            <Avatar
              imageUrl={response.demo_presenter_img || null}
              className={'ml-[10px]'}
              variant={response.demo_presenter_img ? 'image' : 'unidentified'}
              size="extra-large"
            />
          </div>
          <div className="col-5">
            <p>{response.demo_presenter}</p>
            {isValidUrl(response.demo_url) ? (
              <p>
                <a
                  className="overflow-wrap"
                  color="#ff5000 !important"
                  href={formatZoomUrl(response.demo_url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {formatZoomUrl(response.demo_url)}
                </a>
              </p>
            ) : (
              <p className="overflow-wrap" color="#ff5000 !important">
                {formatZoomUrl(response.demo_url)}
              </p>
            )}
          </div>
        </div>
      ),
    }

    const appointmentDetailsData = [section1, section2, section3, section4]
    return appointmentDetailsData
  }

  const LIST_OF_COUNTRIES = Object.entries(rawCountryStates)
    .map(country => ({
      name: country[1].name,
      value: country[1].iso,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const getState = (countryIso, stateIso) => {
    const states = rawCountryStates[countryIso].regions
    const stateData = states.find(stateObj => stateObj.iso === stateIso)
    return {
      name: stateData.name,
      value: stateData.iso || stateData.name,
    }
  }

  const checkAddressInfo = async (address, city, state, country) => {
    if (!address || !city || !state || !country) {
      setAddressNeedsToBeUpdated(true)
    }
  }

  const scheduleFollowup = async apptData => {
    setLoading(true)
    const [demoDetails] = await client(`${ENDPOINT}/${apptData.originalApptId}`)
    demoDetails.cust_country =
      demoDetails.cust_country.length === 2
        ? demoDetails.cust_country
        : iso3toiso2[demoDetails.cust_country.toUpperCase()]
    const followupData = {
      contactNumber: demoDetails.cust_phone,
      demoConductedInSpanish:
        demoDetails.demoLanguage === 'Spanish' ? true : false,
      prospectsCity: apptData.updatedFields.prospectsCity
        ? apptData.updatedFields.prospectsCity
        : demoDetails.cust_city,
      prospectsCompany: demoDetails.cust_company,
      prospectsCountry: apptData.updatedFields.prospectsCountry
        ? apptData.updatedFields.prospectsCountry
        : LIST_OF_COUNTRIES.find(
            countryObj => countryObj.value === demoDetails.cust_country,
          ),
      prospectsDate: apptData.longDate,
      prospectsEmailAddress: demoDetails.cust_email,
      prospectsFirstName: demoDetails.cust_first_name,
      prospectsLastName: demoDetails.cust_last_name,
      prospectsMobilePhone: demoDetails.cust_phone,
      prospectsPlaceURL: demoDetails.cust_url || '',
      prospectsPostalCode: demoDetails.cust_zip,
      prospectsState: apptData.updatedFields.prospectsState
        ? apptData.updatedFields.prospectsState
        : getState(demoDetails.cust_country, demoDetails.cust_state),
      prospectsStreet: apptData.updatedFields.streetAddress
        ? apptData.updatedFields.streetAddress
        : demoDetails.cust_address,
      scheduledDateTime: apptData.ISODate,
      staffId: apptData.selectedStaffId,
      thryvBookingDate: apptData.startDateTime,
      timeZoneId: apptData.timeZoneId,
      timeZoneName: apptData.timeZoneName,
      serviceId: apptData.selectedServiceId,
      isFollowup: true,
    }

    if (followupData.product === 'Marketing Center') {
      delete followupData.problemToSolve
    }

    try {
      await run(client('demos/', {data: followupData}))
      setLoading(false)
      toast.success('Follow up scheduled!')
      setSelectedTime({name: 'Upcoming', value: 'Upcoming'})
    } catch (responseError) {
      setLoading(false)
      if (responseError.status !== 400) {
        throw responseError
      }
      if (
        responseError?.message &&
        responseError.message.includes('select a new date and time')
      ) {
        toast.error(responseError.message, {
          autoClose: false,
          closeOnClick: true,
          theme: 'colored',
        })
      } else {
        toast.error(responseError.message)
      }
    }
    setLoading(false)
  }

  const lastPostIndex = currentPage * recordsPerPage
  const firstPostIndex = lastPostIndex - recordsPerPage

  React.useEffect(() => {
    getRecords({time: getTime()})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime])

  React.useEffect(() => {
    if (isError) {
      throw error
    }
  }, [isError, error])

  React.useEffect(() => {
    if (data) {
      setCurrentRecords(data.slice(firstPostIndex, lastPostIndex))
    }
  }, [data, firstPostIndex, lastPostIndex])

  React.useEffect(() => {
    const userTimeZone = dayjs.tz.guess()
    const timeZoneMatch = timeZones.find(timeZone =>
      userTimeZone.includes(timeZone.value),
    )
    if (timeZoneMatch) {
      setSelectedTimeZone({
        name: timeZoneMatch.name,
        value: timeZoneMatch.value,
      })
    }
  }, [])

  function RenderAppointmentsHeader() {
    if (frontendSideNav) {
      return (
        <DemoAppointmentsHeader
          style={
            !showNewDemoButton
              ? {flexDirection: 'row-reverse', marginTop: '10px'}
              : {marginTop: '10px'}
          }
          frontendSideNav={frontendSideNav}
        >
          <div className="d-flex">
            <LeftAlignedDropdowns frontendSideNav={frontendSideNav}>
              <Select
                options={timeZone}
                width="md"
                selectLabel="Select Time"
                setSelectedOption={setSelectedTime}
                selectedOption={selectedTime}
                className="select-time"
              />
              <Select
                options={timeZones}
                selectLabel="Select Time Zone"
                width="md"
                setSelectedOption={setSelectedTimeZone}
                selectedOption={selectedTimeZone}
                className="select-time"
              />
            </LeftAlignedDropdowns>
            <div className={showNewDemoButton ? 'd-flex' : ''}>
              <SearchButton
                placeholder={`Search ${componentLabelPlural}`}
                onSearch={handleSearch}
                searchOnUsserType={true}
              />
            </div>
          </div>
        </DemoAppointmentsHeader>
      )
    }
    return (
      <DemoAppointmentsHeader>
        <LeftAlignedDropdowns frontendSideNav={frontendSideNav}>
          <Select
            options={timeZone}
            width="md"
            selectLabel="Select Time"
            setSelectedOption={setSelectedTime}
            selectedOption={selectedTime}
            className="select-time"
          />
          <Select
            options={timeZones}
            selectLabel="Select Time Zone"
            width="md"
            setSelectedOption={setSelectedTimeZone}
            selectedOption={selectedTimeZone}
            className="select-time"
          />
        </LeftAlignedDropdowns>
        <div className={showNewDemoButton ? 'd-flex' : ''}>
          {showNewDemoButton && (
            <Button
              className="mr-4"
              variant="primary"
              onClick={() => navigate('/new-demo-request')}
            >
              New Demo
            </Button>
          )}
          <SearchButton
            placeholder={`Search ${componentLabelPlural}`}
            onSearch={handleSearch}
            searchOnUsserType={true}
          />
        </div>
      </DemoAppointmentsHeader>
    )
  }

  return (
    <Container frontendSideNav={frontendSideNav}>
      <ResultsLayoutContainer>
        {RenderAppointmentsHeader()}
        <ResultsTable
          cols={['Company', 'Presenter', 'Date/Time']}
          isLoading={isLoading || loading}
          hasRecords={data?.length > 0}
        >
          {currentRecords &&
            currentRecords.map(
              (
                {
                  id,
                  scheduled_utc,
                  cust_company,
                  cust_country,
                  cust_email,
                  cust_phone,
                  cust_first_name,
                  cust_last_name,
                  cust_state,
                  cust_address,
                  cust_city,
                  cust_zip,
                  demo_presenter,
                  demo_appt_id,
                  demo_url,
                  demo_source,
                  demo_status,
                  demo_timezone,
                  demo_datetime,
                  demo_staff_id,
                  alert_type,
                  lead_id,
                  meeting_type,
                  product,
                  rep_id,
                  wNumber,
                },
                index,
              ) => (
                <tr key={id}>
                  {meeting_type === 'alignment' ? (
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {demo_source === 'Marketplace'
                          ? `${cust_first_name} ${cust_last_name}`
                          : cust_company || '-'}{' '}
                        - Alignment Call
                      </span>
                    </td>
                  ) : (
                    <td>
                      <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                        {demo_source === 'Marketplace'
                          ? `${cust_first_name} ${cust_last_name}`
                          : cust_company || '-'}
                      </span>
                    </td>
                  )}
                  <td>
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      {demo_presenter || '-'}
                    </span>
                  </td>
                  <td>
                    <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                      {dateTimeConverter(
                        scheduled_utc,
                        demo_timezone,
                        'datetime',
                        demo_datetime,
                        selectedTimeZone,
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex justify-content-centern gap-3">
                      <DetailsModal
                        getData={() => setDetailsData(demo_appt_id)}
                        title={
                          meeting_type === 'demo'
                            ? demoModalTitles[product]
                            : modalTitles[meeting_type]
                        }
                        buttonName="Details"
                        meeting_type={meeting_type}
                        selectedTimeZone={selectedTimeZone}
                        height={'40em'}
                      />
                      {/cancelled/i.test(alert_type) ? (
                        <Button
                          variant="secondary"
                          className="text-btn-links font-normal font-montserrat text-thryv-black-500"
                          disabled
                        >
                          Cancelled
                        </Button>
                      ) : (
                        <>
                          {['Tomorrow', 'Upcoming', 'Today'].includes(
                            selectedTime.value,
                          ) && (
                            <>
                              <Button
                                variant="secondary"
                                className="text-btn-links font-normal font-montserrat text-thryv-black-500"
                                onClick={() =>
                                  addToCalendar(scheduled_utc, demo_appt_id)
                                }
                              >
                                Add to Calendar
                              </Button>
                              <>
                                <Modal
                                  variant="default"
                                  title={`Cancel ${componentLabel}`}
                                  btnText="Cancel"
                                  btnType="secondary"
                                  actionClose
                                  footer
                                  action
                                  btnActionText="Confirm"
                                  btnAction={() => cancelDemo(demo_appt_id)}
                                  primaryFooterVariant="dark"
                                >
                                  <ModalTitle variant="subtitle" size="md">
                                    Please confirm {componentLabel}{' '}
                                    cancellation.
                                  </ModalTitle>
                                </Modal>
                              </>
                            </>
                          )}
                          {selectedTime.value === 'Today' ||
                            (selectedTime.value === 'Upcoming' && (
                              <span>
                                <Button
                                  variant="secondary"
                                  className="text-btn-links font-normal font-montserrat text-thryv-black-500"
                                  onClick={() =>
                                    window.open(
                                      formatZoomUrl(demo_url),
                                      '_blank',
                                    )
                                  }
                                >
                                  Join Now
                                </Button>
                              </span>
                            ))}
                          {['Tomorrow', 'Upcoming'].includes(
                            selectedTime.value,
                          ) && (
                            <span>
                              <Button
                                variant="secondary"
                                className="text-btn-links font-normal font-montserrat text-thryv-black-500"
                                disabled
                              >
                                Upcoming
                              </Button>
                            </span>
                          )}
                          {selectedTime.value === 'Past' &&
                            meeting_type === 'demo' && (
                              <>
                                <DateTimeModal
                                  modalTitle="Schedule Follow-up Demo"
                                  buttonText="Schedule Follow-up"
                                  modalId="new-demo-request-modal"
                                  isFollowup={true}
                                  saveDateTime={scheduleFollowup}
                                  availableTimeOptions={{
                                    prospectsCountry: cust_country,
                                    prospectsCity: cust_city,
                                    prospectsState: cust_state,
                                    staffId: demo_staff_id,
                                    originalApptId: demo_appt_id,
                                    streetAddress: cust_address,
                                  }}
                                  updateAddress={addressNeedsToBeUpdated}
                                  onOpen={() =>
                                    checkAddressInfo(
                                      cust_address,
                                      cust_city,
                                      cust_state,
                                      cust_country,
                                    )
                                  }
                                  setAddressNeedsToBeUpdated={
                                    setAddressNeedsToBeUpdated
                                  }
                                />
                                {demo_status !== 'cancelled' &&
                                  frontendDisplayNewOrder && (
                                    <Button
                                      variant="secondary"
                                      className="text-btn-links font-normal font-montserrat text-thryv-black-500"
                                      onClick={() =>
                                        navigate(`/order/new`, {
                                          state: {
                                            order: {
                                              selectedCountry: {
                                                isoValue:
                                                  countryIsoCodeAlpha2(
                                                    cust_country,
                                                  ),
                                                value:
                                                  countryIsoCodeAlpha3(
                                                    cust_country,
                                                  ),
                                              },
                                              customerFirstName:
                                                cust_first_name,
                                              customerLastName: cust_last_name,
                                              customerCompany: cust_company,
                                              customerEmail: cust_email,
                                              customerPhone: cust_phone,
                                              customerState: cust_state,
                                              customerAddress: cust_address,
                                              customerZip: cust_zip,
                                              customerCity: cust_city,
                                              appt: demo_appt_id,
                                              subchannel: 'demo',
                                              leadId: lead_id,
                                              repId: IS_REP_AUS
                                                ? wNumber
                                                : rep_id,
                                              fromDemo: true,
                                            },
                                            currentStep: 2,
                                          },
                                        })
                                      }
                                    >
                                      New Order
                                    </Button>
                                  )}
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ),
            )}
        </ResultsTable>
        {data && (
          <Pagination
            totalRecords={data.length}
            recordsPerPage={recordsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setRecordsPerPage={setRecordsPerPage}
            totalPages={Math.ceil(data.length / recordsPerPage)}
          />
        )}
      </ResultsLayoutContainer>
    </Container>
  )
}

export {DemoAppointments}
