import React from 'react'
import {RadioGroup} from '@thryvlabs/maverick'
import {ADD_ON} from '../../utils/addons-data'
import {AddOnItem} from './add-on-item'
import {ADDONS_TYPES, BILLING_PREFERENCES} from '../../utils/constants'
import {SliderRange} from '../slider-range'
import {WebsiteItem} from './add-on-website'

const RADIO_PLAN_OPTIONS = [
  {
    name: 'Monthly',
    value: 'monthly',
  },
  {
    name: 'Semi-Annually',
    value: 'semiAnnually',
  },
]

const NO_WEBSITE_ENT_PLANS = [
  'sync-4-dexyp-m2m',
  'plus-4-dexyp-m2m',
  'addon-4-mc-m2m',
]

function MonthSemiAnnualSelection({
  item,
  addProduct,
  removeProduct,
  updateProduct,
  currency,
  itemConfig,
  listOfProducts,
  billingPreference,
  totalGmailSeats,
  availableGmailSeats,
  handleUpdateAvailableGmailSeats,
  isAddOnSelected,
  selectedAddOn,
  isDisabled,
  children,
  addBorderBottom = true,
  isTSS,
  isUpgrade = false,
  selectedPlanCode,
  currentAddOns,
}) {
  const [selectedPlan, setSelectedPlan] = React.useState(billingPreference)
  const [includeProduct, setIncludeProduct] = React.useState(false)
  const [currentProduct, setCurrentProduct] = React.useState()
  const [resetToLastValidValue, setResetToLastValidValue] =
    React.useState(false)
  const [showAvailableGmailSeatsError, setShowAvailableGmailSeatsError] =
    React.useState(false)
  const [domainName, setDomainName] = React.useState(null)
  const [disableAddon, setDisableAddon] = React.useState(false)
  const [wasSelected, setWasSelected] = React.useState(false)
  const [wasMCPlusSelected, setWasMCPlusSelected] = React.useState(false)
  const [wasStarterPkgSelected, setWasStarterPkgSelected] =
    React.useState(false)
  const SHOULD_HANDLE_GMAIL_SEATS = [
    'Standard Domain Based Gmail',
    'Certified Domain Based Gmails',
  ].includes(item.name)
  const [{code}] = item.monthly
  const isItemInProductCart = listOfProducts.some(
    ({product, type}) => product === item.name && type === 'addon',
  )
  const isStarterGrowthInCart = listOfProducts.some(
    ({planCode, type}) =>
      ADD_ON.add_ons['Starter Growth Package'].addOnCodes.includes(planCode) &&
      type === ADDONS_TYPES['ADD_ON'],
  )
  const isExpandedGrowthInCart = listOfProducts.some(
    ({planCode, type}) =>
      ADD_ON.add_ons['Expanded Growth Package'].addOnCodes.includes(planCode) &&
      type === ADDONS_TYPES['ADD_ON'],
  )
  const isMCPlusInCart = listOfProducts.some(
    ({planCode, type}) =>
      ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(planCode) &&
      type === ADDONS_TYPES['ADD_ON'],
  )
  const isMCProInCart = listOfProducts.some(({planCode}) =>
    ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(planCode),
  )
  const WEBSITE_ADDON_CODES = [
    'kv1x1fpgv24m',
    'kvtsoc832kz6',
    'kvmt5jfd32b7',
    'kvtt6yf7o5p7',
    'kv1upcfi0yrr',
  ]
  const handleGmailSeatsValidation = quantity => {
    if (SHOULD_HANDLE_GMAIL_SEATS) {
      let quantityToAdd = quantity
      if (currentProduct) {
        quantityToAdd -= currentProduct.quantity

        if (availableGmailSeats === 0) {
          if (quantityToAdd > 0) {
            setResetToLastValidValue(true)
            setShowAvailableGmailSeatsError(true)
            return false
          }
        }
        if (availableGmailSeats - quantityToAdd < 0) {
          setResetToLastValidValue(true)
          setShowAvailableGmailSeatsError(true)
          return false
        }
      }
      setResetToLastValidValue(false)
      handleUpdateAvailableGmailSeats(quantityToAdd)
      return true
    }

    return true
  }
  const getProduct = quantity => {
    const availablePlan =
      item[selectedPlan] ?? item['semiAnnually'] ?? item['monthly']
    const productPayment = availablePlan[0]
    if (!productPayment) return

    const productPrice =
      productPayment.currencies.find(x => x.currency === currency)
        ?.unitAmount || 0

    if (itemConfig.fixedAmount && quantity === 1) {
      quantity = itemConfig.fixedAmount
    }
    let price = Number(
      (quantity ? productPrice * quantity : productPrice).toFixed(2),
    )
    let product = {
      id: productPayment.id,
      product: item.name,
      type: ADDONS_TYPES['ADD_ON'],
      price: `$${price
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      billingPreference:
        selectedPlan === BILLING_PREFERENCES[0]
          ? BILLING_PREFERENCES[0]
          : BILLING_PREFERENCES[1],
      planCode: availablePlan[0].code,
    }
    if (item.name === 'Additional Video Views') {
      product.billingPreference = BILLING_PREFERENCES[0]
    }
    if (quantity) {
      product.quantity = quantity
    }
    return product
  }

  const addWebsiteProduct = productToAdd => {
    const websiteProduct = [productToAdd]
    const availablePlan =
      item[selectedPlan] ?? item['semiAnnually'] ?? item['monthly']
    const designFeePrice = availablePlan[0]?.currencies.find(
      x => x.currency === currency,
    )?.setupFee
    const oneTimeDesignFee = {
      id: availablePlan[0].id,
      product: 'Design Fee',
      planCode: `${availablePlan.code || availablePlan[0]?.code}-setupfee`,
      type: 'oneTime',
      price: `$${Number(designFeePrice).toFixed(2)}`,
      billingPreference: BILLING_PREFERENCES[2],
    }
    websiteProduct.push(oneTimeDesignFee)
    addProduct(websiteProduct)
  }

  const handleAddProduct = quantity => {
    const productToAdd = getProduct(quantity)
    if (!handleGmailSeatsValidation(quantity)) return
    if (productToAdd.product === 'Website' && !isAddOnSelected) {
      addWebsiteProduct(productToAdd)
      setCurrentProduct(productToAdd)
      return
    }
    addProduct([productToAdd])
    setCurrentProduct(productToAdd)
  }

  const handleUpdateProduct = quantity => {
    const productToUpdate = getProduct(quantity)
    if (!handleGmailSeatsValidation(quantity)) return
    setCurrentProduct(productToUpdate)
    updateProduct(productToUpdate, 'product')
  }

  const removeWebsiteProduct = currentProduct => {
    removeProduct([currentProduct.id])
    const websiteProducts = listOfProducts.filter(({id}) =>
      WEBSITE_ADDON_CODES.includes(id),
    )
    websiteProducts.forEach(product => {
      removeProduct([product.id])
    })
  }

  const handleProductSelection = e => {
    setIncludeProduct(e.value === 'Yes')
    if (e.value === 'Yes') {
      handleAddProduct(1)
    } else {
      if (currentProduct) {
        if (SHOULD_HANDLE_GMAIL_SEATS) {
          handleUpdateAvailableGmailSeats(-currentProduct.quantity)
        }
        if (WEBSITE_ADDON_CODES.includes(currentProduct.id)) {
          removeWebsiteProduct(currentProduct)
          setCurrentProduct()
          return
        }
        removeProduct([currentProduct.id])
        setCurrentProduct()
      }
    }
  }

  const handlePlanSelection = e => {
    setSelectedPlan(e.value)
  }

  const handleSlider = qty => {
    if (qty > 0) {
      if (currentProduct.quantity === 0) {
        handleAddProduct(qty)
        return
      }
      handleUpdateProduct(qty)
      return
    }
    if (SHOULD_HANDLE_GMAIL_SEATS) {
      if (isUpgrade && selectedAddOn) {
        setResetToLastValidValue(true)
        return
      }
      handleUpdateAvailableGmailSeats(-currentProduct.quantity)
    }
    removeProduct([currentProduct.id])
    setCurrentProduct(prev => ({
      ...prev,
      quantity: 0,
    }))
  }
  const handleDomainChange = domain => {
    setDomainName(domain)
    listOfProducts.forEach(product => {
      if (product.product === 'Website') {
        product['sensisSiteDomain'] = domainName
      }
    })

    if (domain !== null) {
      removeProduct(null, ({product}) => product !== 'Design Fee')
    } else {
      const isDesignFeeAvailable = listOfProducts.some(
        ({product}) => product === 'Design Fee',
      )
      if (!isDesignFeeAvailable) {
        const websiteProduct = []
        const availablePlan =
          item[selectedPlan] ?? item['semiAnnually'] ?? item['monthly']
        const designFeePrice = availablePlan[0]?.currencies.find(
          x => x.currency === currency,
        )?.setupFee
        const oneTimeDesignFee = {
          id: availablePlan[0].id,
          product: 'Design Fee',
          planCode: `${availablePlan.code || availablePlan[0]?.code}-setupfee`,
          type: 'oneTime',
          price: `$${Number(designFeePrice).toFixed(2)}`,
          billingPreference: BILLING_PREFERENCES[2],
        }
        websiteProduct.push(oneTimeDesignFee)
        addProduct(websiteProduct)
      }
    }
  }
  const getItemDescription = () => {
    const description = ADD_ON.add_ons[item.name]?.description
    if (description) {
      if (
        item.name === ADD_ON.add_ons['Certified Domain Based Gmails'].label ||
        item.name === ADD_ON.add_ons['Standard Domain Based Gmail'].label
      ) {
        return [
          ...description,
          <p key={1} style={{fontWeight: 'bold'}}>
            Based on seats, you have {totalGmailSeats} available email accounts
            to add.
          </p>,
        ]
      }
    }
    return description
  }

  //MC boost addons
  React.useEffect(() => {
    const isStarterGrowthPacakge =
      item.name === ADD_ON.add_ons['Starter Growth Package'].label

    if (
      isStarterGrowthInCart &&
      isExpandedGrowthInCart &&
      isStarterGrowthPacakge
    ) {
      const StarterGrowthPkg = listOfProducts.find(
        ({planCode, type}) =>
          ADD_ON.add_ons['Starter Growth Package'].addOnCodes.includes(
            planCode,
          ) && type === ADDONS_TYPES['ADD_ON'],
      )
      if (StarterGrowthPkg) {
        removeProduct([StarterGrowthPkg.id])
        setWasStarterPkgSelected(true)
      }
    }
    if (
      !isStarterGrowthInCart &&
      !isExpandedGrowthInCart &&
      selectedPlan === 'monthly' &&
      isStarterGrowthPacakge &&
      wasStarterPkgSelected
    ) {
      handleAddProduct(1)
      setWasStarterPkgSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStarterGrowthInCart, isExpandedGrowthInCart, listOfProducts])
  //MC boost addons

  React.useEffect(() => {
    const isMCPlus = item.name === ADD_ON.add_ons['Marketing Center Plus'].label

    if (isMCPlusInCart && isMCProInCart && isMCPlus) {
      const mcPlus = listOfProducts.find(
        ({planCode, type}) =>
          ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
            planCode,
          ) && type === ADDONS_TYPES['ADD_ON'],
      )
      if (mcPlus) {
        removeProduct([mcPlus.id])
        setWasMCPlusSelected(true)
      }
    }
    if (
      !isMCProInCart &&
      !isMCPlusInCart &&
      selectedPlan === 'monthly' &&
      isMCPlus &&
      wasMCPlusSelected
    ) {
      handleAddProduct(1)
      setWasMCPlusSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMCPlusInCart, isMCProInCart, listOfProducts])
  React.useEffect(() => {
    const websiteAddons = ['addon-4-website-sync-m2m', 'addon-4-website-m2m']
    const websiteAddon = listOfProducts.find(({planCode}) =>
      websiteAddons.includes(planCode),
    )
    const websiteEntWithMCProPlans =
      NO_WEBSITE_ENT_PLANS.includes(selectedPlanCode)

    if (websiteAddon && websiteEntWithMCProPlans && isMCProInCart) {
      removeProduct([websiteAddon.id])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfProducts, selectedPlanCode, isMCProInCart])
  React.useEffect(() => {
    if (SHOULD_HANDLE_GMAIL_SEATS) {
      if (availableGmailSeats === 0 && !currentProduct) {
        setDisableAddon(true)
      } else {
        setDisableAddon(false)
      }
    }
  }, [availableGmailSeats, SHOULD_HANDLE_GMAIL_SEATS, currentProduct])
  React.useEffect(() => {
    listOfProducts.forEach(product => {
      if (product.product === 'Website') {
        product['sensisSiteDomain'] = domainName
      }
    })
  }, [domainName]) //eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (showAvailableGmailSeatsError) {
      setTimeout(() => {
        setShowAvailableGmailSeatsError(false)
      }, 4000)
    }
  }, [showAvailableGmailSeatsError])
  React.useEffect(() => {
    if (!isAddOnSelected || wasSelected) return

    if (
      !isItemInProductCart &&
      !wasSelected &&
      !(SHOULD_HANDLE_GMAIL_SEATS && isUpgrade) &&
      item.name !== 'Thryv Leads'
    ) {
      handleAddProduct(selectedAddOn?.quantity || 1)
      setWasSelected(true)
    }
  }, [isAddOnSelected, isItemInProductCart]) //eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (currentProduct) {
      handleUpdateProduct(currentProduct?.quantity || 1)
    }
  }, [selectedPlan]) //eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (SHOULD_HANDLE_GMAIL_SEATS && isUpgrade) {
      if (selectedAddOn?.quantity) {
        handleAddProduct(selectedAddOn.quantity)
        setIncludeProduct(true)
        setResetToLastValidValue(true)
        setWasSelected(true)
      }
    }
  }, [isUpgrade]) //eslint-disable-line react-hooks/exhaustive-deps

  if (item.name === ADD_ON.add_ons['Website'].label) {
    return (
      <WebsiteItem
        key={item.id}
        item={item}
        code={code}
        currentAddOns={currentAddOns}
        listOfProducts={listOfProducts}
        description={ADD_ON.add_ons['Website'].description}
        radioButtonOptions={itemConfig.radioButtonOptions}
        itemConfig={itemConfig}
        handleAddProduct={handleProductSelection}
        handlePlanSelection={handlePlanSelection}
        onDomainChange={handleDomainChange}
        includeProduct={includeProduct}
        billingPreference={selectedPlan}
        isDisabled={isDisabled}
        isTSS={isTSS}
      />
    )
  }
  return (
    <AddOnItem
      key={item.name}
      productId={item.id}
      title={item.name}
      code={code}
      description={getItemDescription()}
      options={itemConfig.radioButtonOptions}
      setState={handleProductSelection}
      addonType="addons"
      listOfProducts={listOfProducts}
      radioButtonDisable={disableAddon}
      isDisabled={isDisabled}
      addBorderBottom={addBorderBottom}
      isMCProInCart={isMCProInCart}
      isMCPlusInCart={isMCPlusInCart}
      wasMCPlusSelected={wasMCPlusSelected}
      isStarterGrowthInCart={isStarterGrowthInCart}
      isExpandedGrowthInCart={isExpandedGrowthInCart}
      wasStarterPkgSelected={wasStarterPkgSelected}
      currentAddOns={currentAddOns}
    >
      {itemConfig.planSelection && (
        <div
          className="row ml-3 mb-4"
          style={!includeProduct ? {opacity: '.4', pointerEvents: 'none'} : {}}
        >
          {billingPreference === 'semiAnnually' && (
            <>
              <RadioGroup
                data={RADIO_PLAN_OPTIONS}
                value={
                  selectedPlan === 'monthly'
                    ? RADIO_PLAN_OPTIONS[0]
                    : RADIO_PLAN_OPTIONS[1]
                }
                row
                onChange={handlePlanSelection}
                layoutStyles={{
                  position: 'relative',
                  top: '-18px',
                  gap: '10px',
                }}
              />
            </>
          )}
        </div>
      )}
      {itemConfig.slider && (
        <div className="ml-3 mb-4">
          <SliderRange
            key={item.name}
            product={item}
            handleOnChange={handleSlider}
            isDisabled={!includeProduct}
            maxValue={itemConfig.maxValue}
            range={itemConfig.range}
            symbolTemplate={itemConfig.symbolTemplate || ''}
            includeProduct={includeProduct}
            lastValidValue={currentProduct?.quantity}
            resetToLastValidValue={resetToLastValidValue}
            setResetToLastValidValue={setResetToLastValidValue}
          />
          {showAvailableGmailSeatsError && (
            <p className="text-danger mt-4 fade-out-animation">
              You cannot exceed {totalGmailSeats} Google Emails.
            </p>
          )}
        </div>
      )}
      {children}
    </AddOnItem>
  )
}

export {MonthSemiAnnualSelection}
