import React, {useCallback, useEffect, useState} from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import {useNavigate, useLocation} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {toast} from 'react-toastify'
import rawCountryStates from 'iso3166-2-db/i18n/en'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {useAuth0} from '@auth0/auth0-react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {PhoneNumberUtil} from 'google-libphonenumber'
import validator from 'validator'
import {
  Input,
  Button,
  Select,
  ParagraphText,
  RadioGroup,
} from '@thryvlabs/maverick'
import 'react-calendar/dist/Calendar.css'

import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import {LIST_OF_STATES} from '../utils/constants'
import {dateTimeConverter} from '../utils/timezones'
import {
  loadGooglePlaceLibScript,
  getPlacePredictions,
  getPlaceDetails,
  getPlaceId,
} from '../utils/google-places-helpers'
import {useUserRoles} from '../utils/use-user-roles'
import {useTrackLR} from '../utils/use-track-lr'

import {DateTimeModal} from '../components/date-time-modal'
import {MainContainer, FormContainer} from '../components/ui/new-demo'

const phoneUtil = PhoneNumberUtil.getInstance()

const getPlacePredictionsDebounce = AwesomeDebouncePromise(
  getPlacePredictions,
  300,
)
const ALIGNMENT_ROLES = [
  'InsideDemo',
  'PremiseDemo',
  'VivialDemo',
  'Admin',
  'Premise',
]

const REGIONS = [
  {
    name: 'East',
    value: 'pn107eljth7kyj8y',
  },
  {
    name: 'MidWest',
    value: 'juh39ircg7jr5xbt',
  },
  {
    name: 'South',
    value: '6v251tu1l7h216xz',
  },
  {
    name: 'Inside Sales',
    value: 'yhjr2kbrar2la7s9',
  },
  {
    name: 'Coastal',
    value: 'pn107eljth7kyj8y',
  },
]

const LEGACY_REGIONS = [
  ...REGIONS,
  {
    name: 'Eastern',
    value: 'pn107eljth7kyj8y',
  },
  {
    name: 'Midwestern',
    value: 'juh39ircg7jr5xbt',
  },
  {
    name: 'Southern',
    value: '6v251tu1l7h216xz',
  },
  {
    name: 'Insidesales',
    value: 'yhjr2kbrar2la7s9',
  },
]

const PLACE_DETAIL_FIELDS = ['name', 'address_components', 'formatted_address']

const REQUIRED_FIELD_MESSAGE = 'This field is required'
const REQUIRED_OPTION_MESSAGE = 'Please select an option'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'
const MOBILE_AREA_CODES = ['021', '022', '027']
const SCHEMA = yup.object().shape({
  prospectsFirstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsLastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsCompany: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsStreet: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsCity: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  prospectsCountry: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  prospectsState: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
  prospectsMobilePhone: yup
    .string()
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country =
            this.options.context.componentProps.selectedCountry.value
          value = value.replace(/[^\d]/g, '')
          const parsedPhone = phoneUtil.parse(value, country)
          const isValidNumber = phoneUtil.isValidNumber(parsedPhone)
          if (
            !isValidNumber &&
            country === 'NZ' &&
            value.length === 9 &&
            MOBILE_AREA_CODES.includes(value.substring(0, 3))
          ) {
            return true
          }
          return isValidNumber
        } catch {
          return false
        }
      },
    ),
  prospectsPostalCode: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-postCode-format',
      'Incorrect post code format',
      function (value) {
        if (!value) {
          return false
        }
        const country =
          this.options.context.componentProps.selectedCountry.value
        if (country === 'BB') {
          return value.match(/^[A-Z]{2}\d{5}$/)
        }
        if (country === 'KY') {
          return value.match(/^[A-Za-z]{2}\d-?\d{4}$/)
        }
        return validator.isPostalCode(value, country)
      },
    ),
  prospectsEmailAddress: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .email('Must be a valid email'),
  contactNumber: yup
    .string()
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country =
            this.options.context.componentProps.selectedCountry.value
          value = value.replace(/[^\d]/g, '')
          const parsedPhone = phoneUtil.parse(value, country)
          const isValidNumber = phoneUtil.isValidNumber(parsedPhone)
          if (
            !isValidNumber &&
            country === 'NZ' &&
            value.length === 9 &&
            MOBILE_AREA_CODES.includes(value.substring(0, 3))
          ) {
            return true
          }
          return isValidNumber
        } catch {
          return false
        }
      },
    ),
  salesforceCustomerID: yup.string().nullable(),
  scheduledDateTime: yup.string().required(REQUIRED_FIELD_MESSAGE),
})
const YELLO_USER_SCHEMA = yup.object().shape({
  yelloAccountID: yup.string().required(REQUIRED_FIELD_MESSAGE),
})
const AUSTRALIA_NZ_USER_SCHEMA = yup.object().shape({
  sensisSalesforceCustomerID: yup.string(),
})
const REGIONS_SCHEMA = yup.object().shape({
  prospectsRegion: yup.object().shape({
    name: yup.string().required(REQUIRED_OPTION_MESSAGE),
    value: yup.string().required(REQUIRED_OPTION_MESSAGE),
  }),
})

const DEFAULT_VALUES = {
  contactNumber: '',
  demoConductedInSpanish: false,
  prospectsStreet: '',
  prospectsCity: '',
  prospectsCompany: '',
  prospectsEmailAddress: '',
  prospectsFirstName: '',
  prospectsLastName: '',
  prospectsMobilePhone: '',
  prospectsState: {},
  prospectsCountry: {},
  prospectsPostalCode: '',
  salesforceCustomerID: '',
  product: '',
  timeZoneId: null,
  timeZoneName: '',
  serviceId: '',
}

const setShowAlignmentCallModal = ({
  roles,
  selectedCountry,
  isAustraliaUser,
  isNZUser,
}) => {
  // if (
  //   isAustraliaUser ||
  //   isNZUser ||
  //   ['AU', 'NZ', 'BB', 'KY'].includes(selectedCountry)
  // )
  //   return false
  // console.log({roles})
  if (
    (isAustraliaUser || isNZUser || ['AU', 'NZ'].includes(selectedCountry)) &&
    ['Admin', 'PremisePilot', 'australia', 'NewZealandSales'].some(item =>
      roles.includes(item),
    )
  )
    return true
  if (
    isAustraliaUser ||
    isNZUser ||
    ['AU', 'NZ', 'BB', 'KY'].includes(selectedCountry)
  )
    return false
  return (
    roles.some(role => ALIGNMENT_ROLES.includes(role)) ||
    selectedCountry === 'CA'
  )
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

function getCompanyLocationInfo(googleAddressComponents) {
  const result = {}
  googleAddressComponents.forEach(({types, long_name}) => {
    switch (types[0]) {
      case 'route':
        if (result['prospectsStreet']) {
          result[
            'prospectsStreet'
          ] = `${result['prospectsStreet']} ${long_name}`
          break
        }
        result['prospectsStreet'] = long_name
        break
      case 'locality':
        result['prospectsCity'] = long_name
        break
      case 'street_number':
        if (result['prospectsStreet']) {
          result[
            'prospectsStreet'
          ] = `${long_name} ${result['prospectsStreet']}`
          break
        }
        result['prospectsStreet'] = long_name
        break
      case 'administrative_area_level_1':
        result['prospectsState'] = long_name
        break
      case 'country':
        result['prospectsCountry'] = long_name
        break
      case 'postal_code':
        result['prospectsPostalCode'] = long_name
        break
      default:
        break
    }
  })
  return result
}

function NewDemo() {
  const RADIO_LANGUAGE_PREFERENCES = [
    {name: 'Yes', value: true},
    {name: 'No', value: false},
  ]
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [demoLanguagePreference, setDemoLanguagePreference] = useState(
    RADIO_LANGUAGE_PREFERENCES[1],
  )
  const [alignmentLanguagePreference, setAlignmentLanguagePreference] =
    useState(RADIO_LANGUAGE_PREFERENCES[1])
  const [selectedCountry, setSelectedCountry] = useState({
    name: 'United States',
    value: 'US',
  })
  const [listOfStates, setListOfStates] = useState([])
  const [selectedRegion, setSelectedRegion] = useState({
    name: '',
    value: '',
  })
  const [selectedState, setSelectedState] = useState({
    name: '',
    value: '',
  })
  const [searchedState, setSearchedState] = useState('')
  const [resetSelectedState, setResetSelectedState] = useState(false)
  const [alignmentStartDateTime, setAlignmentStartDateTime] = useState(null)
  const [demoStartDateTime, setDemoStartDateTime] = useState(null)
  const [selectedTimezone, setSelectedTimezone] = useState(null)
  const [dateTime, setDateTime] = useState(null)
  const [alignmentScheduledDateTime, setAlignmentScheduledDateTime] =
    useState(null)
  const [selectedHour, setSelectedHour] = useState(null)
  const [demoCallStaffId, setDemoCallStaffId] = useState(null)
  const [demoRequestScheduled, setDemoRequestScheduled] = useState(false)
  const [showProspectRegionInput, setShowProspectRegionInput] = useState(true)
  const [availableTimeOptions, setAvailableTimeOptions] = useState({
    demoConductedInSpanish: false,
    prospectsCountry: 'United States',
    isMarketingPlatform: false,
    isFormerVivialCustomer: false,
    regionId: null,
    isMarketingCenter: null,
  })
  const [availableAlignmentTimeOptions, setAvailableAlignmentTimeOptions] =
    useState(availableTimeOptions)
  const [prospectsCompanyName, setProspectsCompanyName] = useState('')
  const [companyPredictions, setCompanyPredictions] = useState([])
  const [selectedPlaceId, setSelectedPlaceId] = useState(null)
  const [noAlignmentTimesAvailable, setNoAlignmentTimesAvailable] =
    useState(false)
  const {roles} = useUserRoles()
  const {user} = useAuth0()
  const flags = useFlags()
  const location = useLocation()
  const client = useAuthClient()
  const navigate = useNavigate()
  const {run} = useAsync({
    status: 'pending',
  })
  const isUsSpanishOptionAvailable = selectedCountry.value === 'US'

  let recordId
  if (location.state) {
    recordId = location.state.recordId
  }

  const RADIO_COUNTRY_SELECTION = [
    {name: 'United States', value: 'US'},
    {name: 'Canada', value: 'CA'},
    {name: 'Australia', value: 'AU'},
    {name: 'New Zealand', value: 'NZ'},
    {name: 'Barbados', value: 'BB'},
    {name: 'Cayman Islands', value: 'KY'},
  ]

  const formattedRoles = roles.map(role =>
    role.toLowerCase().replace(/\s/g, ''),
  )
  const IS_YELLO_USER = formattedRoles.includes('yello')
  const IS_AUSTRALIA_USER = formattedRoles.includes('australia')
  const IS_NZ_USER = formattedRoles.includes('newzealandsales')

  const SHOW_ALIGNMENT_CALL_MODAL = setShowAlignmentCallModal({
    roles,
    selectedCountry: selectedCountry.value,
    isAustraliaUser: IS_AUSTRALIA_USER,
    isNZUser: IS_NZ_USER,
  })

  const setSchema = () => {
    let result = SCHEMA
    if (IS_YELLO_USER) {
      result = result.concat(YELLO_USER_SCHEMA)
    }
    if (IS_AUSTRALIA_USER || IS_NZ_USER) {
      result = result.concat(AUSTRALIA_NZ_USER_SCHEMA)
    }
    if (flags.frontendDemoRegionSelection && selectedCountry.value === 'US') {
      result = result.concat(REGIONS_SCHEMA)
    }
    return result
  }

  if (!document.querySelector('#google-maps-place')) {
    loadGooglePlaceLibScript()
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    trigger,
    watch,
    getValues,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(setSchema()),
    context: {componentProps: {selectedCountry}},
  })

  const [
    watchFirstName,
    watchLastName,
    watchProspectsMobilePhone,
    watchProspectsStreet,
    watchProspectsCity,
    watchProspectsPostalCode,
    watchProduct,
    watchProspectsState,
    watchProspectsDate,
    watchAlignmentScheduledDateTime,
    watchProspectsEmailAddress,
    watchProspectsCompany,
    watchProspectsRegion,
  ] = watch([
    'prospectsFirstName',
    'prospectsLastName',
    'prospectsMobilePhone',
    'prospectsStreet',
    'prospectsCity',
    'prospectsPostalCode',
    'product',
    'prospectsState',
    'prospectsDate',
    'alignmentScheduledDateTime',
    'prospectsEmailAddress',
    'prospectsCompany',
    'prospectsRegion',
  ])
  const marketingCenterDemo = watchProduct === 'Marketing Center'

  const demoSchedulerFields = [
    watchFirstName &&
      watchLastName &&
      watchProspectsMobilePhone &&
      watchProspectsEmailAddress &&
      selectedCountry.name.length > 0 &&
      watchProspectsStreet &&
      watchProspectsCity &&
      watchProspectsState &&
      watchProspectsPostalCode &&
      watchProspectsCompany,
  ]

  const regionCondition =
    flags.frontendDemoRegionSelection &&
    showProspectRegionInput &&
    selectedCountry.value === 'US'
  const demoSchedulerEnabled = regionCondition
    ? demoSchedulerFields && watchProspectsRegion
    : demoSchedulerFields

  const customEvent = watchProspectsEmailAddress &&
    prospectsCompanyName && {
      key: 'demo-request',
      companyName: prospectsCompanyName,
      companyEmail: watchProspectsEmailAddress,
    }
  useTrackLR(customEvent)

  const handleCompanySearch = e => {
    if (e.type === 'click') {
      const companyName = e.target.innerText
      const {placeId} = companyPredictions.find(
        p =>
          p.description.toLowerCase().replace(/ /g, '') ===
          companyName.toLowerCase().replace(/ /g, ''),
      )
      setSelectedPlaceId(placeId)
      return
    }
    const query = e.target.value
    setValue('prospectsCompany', query)
    setProspectsCompanyName(query)
  }

  const handleCompanySearchBlur = e => {
    if (e.relatedTarget?.tagName !== 'BUTTON') {
      setCompanyPredictions([])
    }
  }

  const getStates = useCallback(
    countryIso => {
      const states = rawCountryStates[countryIso].regions
        .map(({iso, name}) => ({
          name,
          value: iso || name,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      setListOfStates(states)
    },
    [setListOfStates],
  )

  const setState = (value, reset) => {
    setSelectedState(value)
    setValue('prospectsState', {...value})
    if (reset) {
      setResetSelectedState(true)
    } else {
      trigger('prospectsState')
    }
  }

  const saveDemoCallData = ({
    longDate,
    selectedStaffId,
    selectedHourTimeStamp,
    ISODate,
    timeZoneId,
    timeZoneName,
    startDateTime,
    selectedServiceId,
    selectedLanguagePreference,
  }) => {
    setDateTime(ISODate)
    setValue('prospectsDate', longDate)
    setValue('staffId', selectedStaffId)
    setDemoCallStaffId(selectedStaffId)
    setSelectedHour(selectedHourTimeStamp)
    setDemoStartDateTime(startDateTime)
    setValue('scheduledDateTime', ISODate)
    setValue('timeZoneId', timeZoneId)
    setValue('timeZoneName', timeZoneName)
    setSelectedTimezone(timeZoneId)
    setValue('serviceId', selectedServiceId)
    trigger(['scheduledDateTime', 'prospectsDate', 'staffId'])
    setValue('demoConductedInSpanish', selectedLanguagePreference.value)
  }

  const saveAlignmentCallData = ({
    ISODate,
    alignmentCallTimesAvailable,
    startDateTime,
    selectedServiceId,
    selectedLanguagePreference,
  }) => {
    if (!alignmentCallTimesAvailable) {
      setNoAlignmentTimesAvailable(true)
    } else {
      setValue('alignmentScheduledDateTime', ISODate)
      setAlignmentScheduledDateTime(ISODate)
      setAlignmentStartDateTime(startDateTime)
      setValue('alignmentServiceId', selectedServiceId)
      trigger('alignmentScheduledDateTime')
      setValue('demoConductedInSpanish', selectedLanguagePreference.value)
    }
  }

  const updateAvailableTimeOptions = ({name, value}) => {
    const options = {
      ...availableTimeOptions,
      [name]: value,
    }
    setAvailableTimeOptions(options)
    setDateTime(null)
  }

  const updateAvailableAlignmentTimeOptions = ({name, value}) => {
    const options = {
      ...availableAlignmentTimeOptions,
      [name]: value,
    }
    setAvailableAlignmentTimeOptions(options)
  }

  const submitForm = async formData => {
    setIsFormLoading(true)
    const DATA = {
      ...formData,
      thryvBookingDate: demoStartDateTime,
    }
    if (alignmentStartDateTime) {
      DATA.alignmentThryvBookingDate = alignmentStartDateTime
    }
    let placeId = await getPlaceId({
      fields: ['place_id'],
      query: `${DATA.prospectsCompany} ${DATA.prospectsStreet} ${DATA.prospectsPostalCode} ${DATA.propspectsCity} ${DATA.prospectsState}`,
    })
    if (placeId === null) {
      placeId = selectedPlaceId
    } else {
      placeId = placeId[0]?.place_id
    }
    DATA.prospectsPlaceURL = `https://www.google.com/maps/place/?q=place_id:${placeId}`
    try {
      await run(client('demos/', {data: DATA}))
      setDemoRequestScheduled(true)
      setIsFormLoading(false)
      toast.success('Form submitted successfully')
    } catch (responseError) {
      setIsFormLoading(false)
      if (responseError.status !== 400) {
        throw responseError
      }
      if (
        responseError?.message &&
        responseError.message.includes('select a new date and time')
      ) {
        toast.error(responseError.message, {
          autoClose: false,
          closeOnClick: true,
          theme: 'colored',
        })
      } else {
        toast.error(responseError.message)
      }
    }
    setIsFormLoading(false)
  }

  useEffect(() => {
    if (
      watchProduct === 'Business Center' ||
      watchProduct === 'Marketing Center'
    ) {
      updateAvailableTimeOptions({
        name: 'product',
        value: watchProduct,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProduct])

  useEffect(() => {
    updateAvailableTimeOptions({
      name: 'demoConductedInSpanish',
      value: demoLanguagePreference.value,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoLanguagePreference])

  useEffect(() => {
    updateAvailableAlignmentTimeOptions({
      name: 'demoConductedInSpanish',
      value: alignmentLanguagePreference.value,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alignmentLanguagePreference])

  useEffect(() => {
    const countrySelection = {
      name: IS_AUSTRALIA_USER
        ? 'Australia'
        : IS_NZ_USER
        ? 'New Zealand'
        : 'United States',
      value: IS_AUSTRALIA_USER ? 'AU' : IS_NZ_USER ? 'NZ' : 'US',
    }
    setSelectedCountry(countrySelection)
    setValue('prospectsCountry', countrySelection)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IS_AUSTRALIA_USER, IS_NZ_USER])

  useEffect(() => {
    const contingentFields = [
      'prospectsMobilePhone',
      'contactNumber',
      'prospectsPostalCode',
    ]
    if (selectedCountry.value.length > 0) {
      getStates(selectedCountry.value)
    }
    contingentFields.forEach(field => {
      if (getValues(field)) {
        trigger(field)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  useEffect(() => {
    if (flags.frontendDemoRegionSelection) {
      const fetchUserRegion = async () => {
        const data = await client('user/region')
        if (data) {
          const regionId = LEGACY_REGIONS.find(
            region => region.name.toLowerCase() === data.toLowerCase(),
          )?.value
          if (!regionId) {
            return
          }
          setSelectedRegion({
            name: data,
            value: regionId,
          })
          setValue('prospectsRegion', {
            name: data,
            value: regionId,
          })
          setShowProspectRegionInput(false)
        }
      }
      fetchUserRegion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  useEffect(() => {
    setAvailableTimeOptions({
      ...availableTimeOptions,
      prospectsCountry: selectedCountry.name,
      regionId: selectedRegion.value,
    })
    setAvailableAlignmentTimeOptions({
      ...availableAlignmentTimeOptions,
      prospectsCountry: selectedCountry.name,
      regionId: selectedRegion.value,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion, selectedCountry])

  useEffect(() => {
    setAvailableTimeOptions({
      ...availableTimeOptions,
      isMarketingCenter: marketingCenterDemo,
    })
    setAvailableAlignmentTimeOptions({
      ...availableAlignmentTimeOptions,
      isMarketingCenter: marketingCenterDemo,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketingCenterDemo])

  useEffect(() => {
    if (prospectsCompanyName?.length > 3) {
      ;(async () => {
        const predictions = await getPlacePredictionsDebounce({
          input: prospectsCompanyName,
          componentRestrictions: {country: ['us', 'ca', 'au', 'nz']},
        })
        let predictionsResult = []
        if (predictions) {
          predictionsResult = predictions.map(
            ({place_id: placeId, description}) => ({placeId, description}),
          )
        }
        setCompanyPredictions(predictionsResult)
      })()
    } else {
      setCompanyPredictions([])
    }
  }, [prospectsCompanyName])

  useEffect(() => {
    if (selectedPlaceId) {
      ;(async () => {
        const placeInfo = await getPlaceDetails({
          placeId: selectedPlaceId,
          fields: PLACE_DETAIL_FIELDS,
        })
        const companyInfo = getCompanyLocationInfo(placeInfo.address_components)
        Object.keys(companyInfo).forEach(field => {
          if (field === 'prospectsState') {
            setSearchedState(companyInfo[field])
            return
          }
          if (field === 'prospectsCountry') {
            const countryObject = RADIO_COUNTRY_SELECTION.find(
              ({name}) => name === companyInfo.prospectsCountry,
            )
            if (!countryObject) return
            setValue('prospectsCountry', countryObject)
            setSelectedCountry(countryObject)
            return
          }
          setValue(field, companyInfo[field])
        })
        setProspectsCompanyName(placeInfo.name)
        setValue('prospectsCompany', placeInfo.name)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceId])

  useEffect(() => {
    if (!searchedState) return
    const stateObject = listOfStates.find(state => state.name === searchedState)
    if (!stateObject) return
    setState(stateObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedState, listOfStates])

  const fetchCompanyDetails = async () => {
    const response = await client(`scans/${recordId}`)
    setValue('prospectsStreet', response.address)
    setValue('prospectsPostalCode', response.zip)
    setValue('prospectsCity', response.city)
    setValue('prospectsMobilePhone', response.business_phone)
    setProspectsCompanyName(response.business_name)
    setValue('prospectsCompany', response.business_name)
    const country = RADIO_COUNTRY_SELECTION.find(
      ({name}) => name === response.country,
    )
    setSelectedCountry(country)
    setValue('prospectsCountry', country)
    const state = LIST_OF_STATES.find(({name}) => name === response.state)
    if (state?.value) {
      setValue('prospectsState', state)
      setSelectedState(state)
    }
  }

  useEffect(() => {
    if (recordId) {
      fetchCompanyDetails()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue('product', 'Business Center')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (demoRequestScheduled) {
    return (
      <>
        <MainContainer className="d-flex flex-column justify-content-center align-content-center">
          <div className="font-montserrat text-center">
            <p className="mb-4">
              {user.name}, we&apos;ve received your demo request for:
            </p>
            <h2>
              {dateTimeConverter(
                demoStartDateTime,
                selectedTimezone,
                'confirmation',
              )}
            </h2>
            <div className=" mb-2">
              <p className="m-1">{getValues('prospectsFirstName')}</p>
              <p className="m-1">{getValues('prospectsLastName')}</p>
              <p className="m-1">{getValues('prospectsMobilePhone')}</p>
              <p className="m-1">{getValues('prospectsEmailAddress')}</p>
            </div>
            {alignmentScheduledDateTime ? (
              <p>
                <b>
                  Your alignment call is scheduled for&nbsp;
                  {dateTimeConverter(
                    alignmentScheduledDateTime,
                    selectedTimezone,
                    'confirmation',
                  )}
                </b>
              </p>
            ) : null}
          </div>
          <div
            className="m-4 p-5"
            style={{backgroundColor: '#f2f3f4', borderRadius: '25px'}}
          >
            <h2 className="font-montserrat text-center">
              Recommendations for Demo
            </h2>
            <ol>
              <li className="font-normal font-montserrat text-thryv-black-500">
                1. Be early.
              </li>
              <li className="font-normal font-montserrat text-thryv-black-500">
                2. Connect to the internet.
              </li>
              <li className="font-normal font-montserrat text-thryv-black-500">
                3. Be on video & introduce the Software Sales Teammate.
              </li>
              <li className="font-normal font-montserrat text-thryv-black-500">
                4. Allow Software Sales Teammate to run the 45 minute
                experience.
              </li>
              <li className="font-normal font-montserrat text-thryv-black-500">
                5. Take notes on needs and be prepared to provide
                recommendations when demo is completed.
              </li>
            </ol>
          </div>
          <hr />
          <Button
            variant="primary"
            className="w-50 align-self-center"
            style={{maxWidth: '250px'}}
            onClick={() => navigate('/demo-requests')}
          >
            Back to Demo Requests
          </Button>
        </MainContainer>
      </>
    )
  }

  return (
    <>
      <MainContainer>
        {isFormLoading ? (
          <ParagraphText variant="reg" className="h3 mb-5 text-center">
            Preparing your demo.
          </ParagraphText>
        ) : (
          <ParagraphText variant="reg" className="h3 mb-5 text-center">
            Tell us about your potential business
          </ParagraphText>
        )}
        {isFormLoading ? (
          <div className="d-flex justify-content-center">
            <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
              <Skeleton
                containerTestId="new-demo-loader"
                height="70vh"
                width="70vw"
              />
            </SkeletonTheme>
          </div>
        ) : (
          <FormContainer>
            <div className="section-title">
              <ParagraphText variant="reg" className="h5">
                Client Information
              </ParagraphText>
            </div>
            <Input
              className="w-100"
              type="text"
              placeholder="First Name"
              aria-label="First Name"
              name="prospectsFirstName"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
            <Input
              className="w-100"
              type="text"
              placeholder="Last Name"
              aria-label="Last Name"
              name="prospectsLastName"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
            <Input
              className="w-100"
              type="text"
              placeholder="Mobile Phone"
              aria-label="Mobile Phone"
              name="prospectsMobilePhone"
              maxLength={18}
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
            <Input
              className="w-100"
              type="text"
              placeholder="Email Address"
              aria-label="Email Address"
              name="prospectsEmailAddress"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
            <div className="section-title">
              <ParagraphText
                variant="reg"
                className="h5"
                style={{marginBottom: '-1.5rem'}}
              >
                Select country
              </ParagraphText>
              <Controller
                control={control}
                name="prospectsCountry"
                render={({field: {onChange, name}}) => (
                  <RadioGroup
                    data={RADIO_COUNTRY_SELECTION}
                    value={selectedCountry}
                    onChange={selectedObject => {
                      const selectedCountry = RADIO_COUNTRY_SELECTION.find(
                        option => option.value === selectedObject.value,
                      )
                      onChange(selectedCountry)
                      setSelectedCountry(selectedCountry)
                    }}
                    name={name}
                    row
                    layoutStyles={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '-5px',
                    }}
                    register={register}
                  />
                )}
              />
            </div>
            <div className="section-title">
              <ParagraphText
                variant="reg"
                className="h5"
                style={{marginTop: '1rem'}}
              >
                Business Information
              </ParagraphText>
            </div>
            <div className="section-title">
              <Input
                className="w-100 span-cols search-input"
                type="text"
                placeholder="Business Name"
                aria-label="Business Name"
                name="prospectsCompany"
                withLabel
                variant="search"
                value={prospectsCompanyName}
                searchOptions={companyPredictions.map(p => p.description)}
                onChange={handleCompanySearch}
                onBlur={handleCompanySearchBlur}
                register={register}
                errors={errors}
              />
            </div>
            <div className="section-title">
              <Input
                className="w-100"
                type="text"
                placeholder="Address"
                aria-label="Address"
                name="prospectsStreet"
                withLabel
                labelType="floating"
                variant="default"
                register={register}
                errors={errors}
              />
            </div>
            <div className="section-title business-information-bottom">
              <Input
                className="business-information-input"
                type="text"
                placeholder="City"
                aria-label="City"
                name="prospectsCity"
                withLabel
                labelType="floating"
                variant="default"
                register={register}
                errors={errors}
              />
              <Controller
                control={control}
                name="prospectsState"
                render={({field: {onChange, name}}) => (
                  <Select
                    className="business-information-select"
                    options={listOfStates}
                    width="md"
                    selectLabel="State"
                    selectedOption={selectedState}
                    setSelectedOption={setState}
                    errors={errors}
                    onChange={onChange}
                    name={name}
                    cleared={resetSelectedState}
                    setCleared={setResetSelectedState}
                    withLabel={selectedState.value}
                    register={register}
                  />
                )}
              />
              <Input
                className="business-information-input"
                type="text"
                placeholder="Zip"
                aria-label="Zip"
                name="prospectsPostalCode"
                withLabel
                labelType="floating"
                variant="default"
                register={register}
                errors={errors}
              />
            </div>
            {flags.frontendDemoRegionSelection &&
              showProspectRegionInput &&
              selectedCountry.value === 'US' && (
                <div className="section-title">
                  <div className="select-container z-3 mb-3">
                    <Controller
                      control={control}
                      name="prospectsRegion"
                      render={({field: {onChange, name}}) => (
                        <Select
                          className="business-information-input"
                          options={REGIONS}
                          width="md"
                          selectLabel="Region"
                          selectedOption={selectedRegion}
                          setSelectedOption={setSelectedRegion}
                          errors={errors}
                          onChange={onChange}
                          name={name}
                          withLabel={selectedRegion.value}
                          register={register}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

            <div className="scheduler-container-wrap">
              <div className="scheduler-container">
                <ParagraphText variant="reg" className="h5 mb-3">
                  Demo Call
                </ParagraphText>
                {demoSchedulerEnabled ? (
                  <DateTimeModal
                    modalTitle="Appointment Scheduler"
                    buttonText={selectedHour ? 'Edit' : 'select date and time'}
                    modalId="new-demo-request-modal"
                    saveDateTime={saveDemoCallData}
                    availableTimeOptions={availableTimeOptions}
                    register={register}
                    errors={errors}
                    address={`${encodeURIComponent(
                      watchProspectsStreet,
                    )}%20${watchProspectsCity}%20${watchProspectsState.value}`}
                    defaultDateTime={watchProspectsDate}
                    isUsSpanishOptionAvailable={isUsSpanishOptionAvailable}
                    callType="demo"
                    radioLanguageOptions={RADIO_LANGUAGE_PREFERENCES}
                    setLanguagePreference={setDemoLanguagePreference}
                    country={selectedCountry.value}
                  />
                ) : (
                  <Button disabled={true} variant="primary">
                    select date and time
                  </Button>
                )}
              </div>
              {SHOW_ALIGNMENT_CALL_MODAL && (
                <div className="scheduler-container">
                  <>
                    <ParagraphText variant="reg" className="h5 mb-3">
                      Alignment Call
                    </ParagraphText>
                    {selectedHour ? (
                      <DateTimeModal
                        modalTitle="Alignment Call Scheduler"
                        buttonText={
                          alignmentStartDateTime
                            ? 'Edit'
                            : noAlignmentTimesAvailable
                            ? 'No Alignment Times Available'
                            : 'select date and time'
                        }
                        modalId="new-alignment-request-modal"
                        saveDateTime={saveAlignmentCallData}
                        availableTimeOptions={availableAlignmentTimeOptions}
                        isAlignmentCall={true}
                        alignmentCallTimesAvailable={true}
                        staffId={demoCallStaffId}
                        maxDate={dateTime}
                        defaultDate={dateTime}
                        selectedTimeStamp={selectedHour}
                        register={register}
                        disabled={!selectedHour}
                        errors={errors}
                        callType="alignment"
                        address={`${encodeURIComponent(
                          watchProspectsStreet,
                        )}%20${watchProspectsCity}%20${
                          watchProspectsState.value
                        }`}
                        defaultDateTime={
                          watchAlignmentScheduledDateTime
                            ? dayjs(watchAlignmentScheduledDateTime).format(
                                'MMM-DD-YYYY HH:mm a',
                              )
                            : null
                        }
                        isUsSpanishOptionAvailable={isUsSpanishOptionAvailable}
                        radioLanguageOptions={RADIO_LANGUAGE_PREFERENCES}
                        setLanguagePreference={setAlignmentLanguagePreference}
                        country={selectedCountry.value}
                      />
                    ) : (
                      <Button disabled={true} variant="primary">
                        select date and time
                      </Button>
                    )}
                  </>
                </div>
              )}
            </div>
            <div className="section-title">
              <ParagraphText
                variant="reg"
                className="h5"
                style={{marginTop: '1.5rem'}}
              >
                What number can the demo team contact you?
              </ParagraphText>
            </div>
            <Input
              className="w-100 "
              type="text"
              placeholder="Phone Number"
              aria-label="Phone Number"
              name="contactNumber"
              maxLength={18}
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
            <Input
              className="w-100"
              type="text"
              placeholder="Salesforce Customer ID (Optional)"
              aria-label="Salesforce Customer ID (Optional)"
              name="salesforceCustomerID"
              withLabel
              labelType="floating"
              variant="default"
              register={register}
              errors={errors}
            />
            <div className="span-cols input-group d-flex justify-content-center">
              <Button
                disabled={
                  SHOW_ALIGNMENT_CALL_MODAL && !noAlignmentTimesAvailable
                    ? !alignmentStartDateTime
                    : !demoStartDateTime
                }
                variant="primary"
                type="submit"
                className="new-order-button d-flex justify-content-around align-items-center mr-4"
                onClick={handleSubmit(submitForm)}
              >
                Schedule Demo
              </Button>
            </div>
          </FormContainer>
        )}
      </MainContainer>
    </>
  )
}

export {NewDemo}
