import styled from '@emotion/styled'
import {device} from '../../styles/media-queries'

export const MainContainer = styled.section`
  width: auto;
  height: auto;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: 10px;
  padding: 20px 2rem;
  border: 1px solid rgba(227, 227, 227, 0.1);
  background-color: rgba(227, 227, 227, 0.1);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);

  & .react-loading-skeleton {
    max-width: 1100px;
  }

  @media screen and ${device.laptop} {
    width: 95vw;
  }

  & .text-thryv-steel {
    --tw-text-opacity: 1;
    color: rgba(128, 128, 128, var(--tw-text-opacity));
  }
`

export const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  & input[type='radio'] {
    cursor: pointer;
  }
  & .input-group {
    display: flex;
    justify-content: space-between;
  }
  & .input-group input {
    width: 330px;
  }
  & .span-cols {
    grid-column: 1 / 3;
  }
  & p {
    margin: 0;
  }
  & textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 200px;
  }
  & .select-container {
    position: relative;
    z-index: 1;
    & ul {
      height: auto !important;
      padding: 0 !important;
      max-height: 250px;
    }
  }
  & .span-cols .select-container {
    width: 300px;
    & > div > div > div > div > div {
      margin-top: 0 !important;
    }
  }
  & .span-cols > div > label {
    width: 100%;
  }
  & > button {
    max-width: 250px;
  }
  & > div.relative,
  div.span-cols {
    margin-bottom: 24px;
  }
  @media screen and ${device.desktopL} {
    & .span-cols.input-group div {
      width: 22em;
    }
  }
  @media screen and ${device.laptop} {
    & .span-cols.input-group div {
      width: 18em;
    }
  }
  @media screen and ${device.tablet} {
    & .span-cols.input-group div {
      width: 13em;
    }
  }
  @media screen and ${device.mobileL} {
    grid-template-columns: 1fr;
    & .span-cols.input-group div {
      width: 100%;
    }
    & .span-cols {
      grid-column: 1 / 1;
      & .relative {
        width: 100%;
      }
      & .select-container {
        width: 100%;
        margin: 2.5rem 0 2rem 0;
      }
    }
  }
  & .section-title {
    grid-column: 1 / 3;
  }
  & .business-select {
    margin-top: -7px;
  }
  & .business-information-input {
    width: 300px;
  }
  & .business-information-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  & .business-information-select {
    width: calc(33.33%);
    margin-top: -8px;
  }
  & .scheduler-container-wrap {
    grid-column: 1 / 3;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
  }
  & .scheduler-container {
    margin-top: 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: calc(33.33%);
    height: 100%;
    padding: 25px 25px 25px 25px;
  }
  & .scheduler-container.after-hours-container {
    width: auto;
    height: fit-content;
    padding: 0;
    margin-left: 0;
    border: none;
    font-size: 14px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }
  & .scheduler-container button {
    &:not([disabled]) {
      border-color: rgba(255, 80, 1, var(--tw-text-opacity)) !important;
      color: rgba(255, 80, 1, var(--tw-text-opacity));
      background-color: transparent;

      &:hover {
        color: white !important;
        background-color: rgba(255, 80, 1, var(--tw-text-opacity)) !important;
      }
    }
  }
  & .text-btn-links {
    color: rgba(5, 122, 255, var(--tw-text-opacity)) !important;
    pointer-events: none !important;
  }
  & .dateTimeContainer {
    justify-content: center;
  }
  & .additional-information {
    @media (max-width: 576px) {
      width: 200px;
    }
    @media (min-width: 577px) and (max-width: 992px) {
      width: 500px;
    }
    @media (min-width: 993px) and (max-width: 1135px) {
      width: 800px;
    }
    @media (min-width: 1136px) {
      width: 1135px;
    }
  }
`

export const ModalContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'select-date-time';
  justify-items: center;
  align-items: top;
  padding: 2em 4em;
  @media screen and ${device.tablet} {
    grid-template-columns: 100%;
    grid-template-areas:
      'sales-info'
      'select-date-time';
    grid-gap: 10px;
  }
`
export const SelectDateTimeContainer = styled.div`
  grid-area: select-date-time;
  display: flex;
  max-height: 700px;
  & label {
    width: 100%;
  }
  & .calendar {
    width: 400px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    & .react-calendar {
      font-family: 'Montserrat';
      font-size: 15px;
    }
    & .react-calendar__navigation__label__labelText {
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
    }
    & .react-calendar__tile--active {
      background-color: transparent;
      color: black;
      border: 1px solid #fe7275;
      border-radius: 5px;
    }
  }
  & .hours-container {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    height: 90%;
    width: 150px;
    overflow-y: auto;
    & > span {
      height: 90%;
    }
  }
  & .hours-container.isLoading {
    & > span {
      height: auto;
    }
  }
  & .hour-option {
    margin: 5px 0;
    opacity: 0.7;
  }
  & .hour-option:hover {
    opacity: 1;
  }
  & .hour-option.selected {
    background-color: rgb(215, 220, 230);
    opacity: 1;
  }
  & textarea {
    height: 150px;
  }
  @media screen and ${device.mobileL} {
    flex-direction: column;

    & .calendar {
      width: 400px;
      height: 350px;
    }
    & .hours-container {
      width: 100%;
      max-height: 20%;
    }
  }
`
